import styles from "./HelpCenter.module.css";
import Header from "../components/HomePage/Header";
import BannerArea from "../components/HelpCenter/BannerArea";
import HelpCenterTabsArea from "../components/HelpCenter/HelpCenterTabsArea";
import Footer from "../components/HomePage/Footer";
import FooterPrivacy from "../components/HomePage/FooterPrivacy";
import FloatingContactButton from "../components/EmailForm/FloatingContactButton";
import QuickLinksbox from "../components/HelpCenter/QuickLinksbox";
import GetGlobalTrade from "../components/HelpCenter/GetGlobalTrade";

const HelpCenter = () => {
  return (
    <div className={styles.MainPage}>
      <Header />
      <BannerArea />
      {/* <SearchList /> */}
      <HelpCenterTabsArea />
      <QuickLinksbox />
      <GetGlobalTrade />
      <Footer />
      <FooterPrivacy />
      <FloatingContactButton />
    </div>
  );
};

export default HelpCenter;