import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./HelpCenterTabsArea.module.css";
import Container from 'react-bootstrap/Container';
import { Col, Row, Nav, Tab, Button, Modal, Form } from "react-bootstrap";
import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import 'react-phone-input-2/lib/style.css';
import { useNavigate } from "react-router-dom"; // Import useNavigate
import * as z from "zod";

const formSchema = z.object({
    email: z.string().email({ message: "Invalid email address" }),
    firstName: z.string().min(1, { message: "First name is required" }),
    lastName: z.string().min(1, { message: "Last name is required" }),
    companyName: z.string().min(1, { message: "Company name is required" }),
    feedback: z.string().min(1, { message: "Message is required" }),
    subject: z.string().min(1, { message: "Subject name is required" }),
    priority: z.string().min(1, { message: "Priority is required" }),
    phoneNumber: z.string().min(1, { message: "Phone number is required" }),
})

function HelpCenterTabsArea() {

    const handleChangeLogin = () => {
        window.open("https://dashboard2.exportgenius.in/help-support.aspx", "_blank");
    }

    const handleChangeTicket = () => {
        window.open("https://dashboard2.exportgenius.in/AddTicket.aspx", "_blank");
    }

    const [show, setShow] = useState(false);
    const [zohoShow, setZohoShow] = useState(false);

    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState(false)

    const {
        register,
        handleSubmit,
        control,
        reset, // Import reset function
        formState: { errors },
    } = useForm({
        resolver: zodResolver(formSchema),
    })

    const onSubmit = async (data) => {
        setSubmitting(true)
        try {
            const apiPayload = {
                // "type": "support",
                "firstName": data.firstName,
                "lastName": data.lastName,
                "companyName": data.companyName,
                "countryCode": data.companyName,
                "contactNumber": data.phoneNumber,
                "workEmail": data.email,
                "message": data.feedback,
                "subject": data?.subject || "test",
                "priority": data.priority,
                "url": window.location.href
            }
            try {
                const response = await fetch(
                    "https://fjgjyxhtdds.marketinsidedata.com/api/send-email-eg",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(apiPayload),
                    }
                )
                const result = await response.json()
                console.log("result========>", response);
                if (response.ok) {
                    navigate('/thankyou');
                    reset();
                    setSubmitting(false)
                    alert("Form submitted successfully!");
                    reset();
                } else {
                    const errorDetail = result?.message || "Submission failed";
                    alert(`Form submission failed: ${errorDetail}`);
                }
            } catch (error) {
                console.error("Error:", error);
            }

        } catch (error) {
            console.error("Form submission error:", error)
            alert("There was an error submitting the form. Please try again.")
        } finally {
            setSubmitting(false)
        }
    }

    return (

        <div className={styles.HelpCenterTabsAreaBg}>
            <Container>
                <Row>
                    <Col md={12}>
                        <div className={styles.areyouNew}>
                            Are You New Or Existing
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first" >
                        <Col sm={12} md={3}>
                            <div className={styles.navHeadnLetside}>
                                <Nav variant="pills" className="flex-column custom-tabs nav nav-pills nav-fill">
                                    <Nav.Item className={styles.navItemClsmdn}>
                                        <Nav.Link className={styles.navItemLlink} eventKey="first">  Existing Customer  </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item className={styles.navItemClsmdn}>
                                        <Nav.Link className={styles.navItemLlink} eventKey="second"> New Customer </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                        </Col>
                        <Col sm={12} md={9}>
                            <div className={styles.tabXConterares}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <div className={styles.tabPaneCenter}>
                                            <div className={styles.loginInVector}>
                                                <a className={styles.lingNanchTag} > <img alt="login" src="/vector-login.png" /> Not Able To Log In</a>
                                                <h6 className={styles.heheloGet}>
                                                    <a onClick={() => setZohoShow(true)}>
                                                        Get Help
                                                    </a>
                                                </h6>
                                            </div>
                                            <div className={styles.tabpNecenflex}>
                                                <div className={styles.EmailImgeicn}>
                                                    <a href="mailto:info@exportgenius.in">
                                                        <img src="email.png" alt="email" /> <span>Email</span>
                                                        <p>Reach us via email for detailed help.</p>
                                                    </a>
                                                </div>
                                                <div className={styles.EmailImgeicn}>
                                                    <a role="button" onClick={() => (window)?.$zoho?.salesiq?.chat?.start()}>
                                                        <img src="live-chat.png" alt="Live Chat" /> <span>Chat</span>
                                                        <p>Connect with our support team instantly through live chat.</p>
                                                    </a>
                                                </div>
                                                <div className={styles.EmailImgeicn}>
                                                    <a href="tel:+918003800357">
                                                        <img src="phone-call.png" alt="email" /> <span>Phone</span>
                                                        <p>Call us directly for quick and personalized support.</p>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className={styles.loginInVector}>
                                                <a className={styles.lingNanchTag} > <img src="/vector-error.png" /> Able To Log In But Other Issues</a>                                               
                                            </div>
                                            <div className={styles.CreatndLongbtn} >
                                                <Button type="button" className={styles.longTicketBtn} onClick={handleChangeLogin} >Help & Support</Button>
                                                <Button type="button" className={styles.longTicketBtn} onClick={handleChangeTicket}>Create Ticket</Button>
                                            </div>
                                        </div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="second">
                                        <div className={styles.tabPaneCenter}>
                                            <h3 className={styles.salesQuery}>Sales Query</h3>
                                            <div className={styles.cunsQuery}>
                                                <h4 onClick={() => setShow(true)}> <img src="/customer-query.png" /> Connect with Our Sales Agent </h4>
                                            </div>
                                            <h3 className={styles.genralsQuery}>General Query </h3>
                                            <div className={styles.tabpNecenflex}>

                                                <div className={styles.EmailImgeicn}>
                                                    <a href="mailto:info@exportgenius.in">
                                                        <img src="email.png" alt="email" /> <span>Email</span>
                                                        <p>Reach us via email for detailed help.</p>
                                                    </a>
                                                </div>
                                                <div className={styles.EmailImgeicn}>
                                                    <a role="button" onClick={() => (window)?.$zoho?.salesiq?.chat?.start()}>
                                                        <img src="live-chat.png" alt="Live Chat" /> <span>Chat</span>
                                                        <p>Connect with our support team instantly through live chat.</p>
                                                    </a>
                                                </div>
                                                <div className={styles.EmailImgeicn}>
                                                    <a href="tel:+918003800357">
                                                        <img src="phone-call.png" alt="email" /> <span>Phone</span>
                                                        <p>Call us directly for quick and personalized support.</p>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.procutQuerty}>
                                            <h3 className={styles.salesQuery}>Product Query</h3>
                                            <div className={styles.formAareasboxx}>
                                                <Form onSubmit={handleSubmit(onSubmit)} className={styles.modalFormhndl}>
                                                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextFname">
                                                        <Col md="12" lg="8" sm="12">
                                                            <Form.Label className={styles.formNameDetails}>
                                                                Full Name
                                                            </Form.Label>
                                                            <Form.Control
                                                                {...register("firstName")}
                                                                name="firstName"
                                                                type="text"
                                                                required
                                                                className={styles.formControlDetails}
                                                            />
                                                            {errors.firstName && (
                                                                <p className="mt-1 text-xs text-red-500">
                                                                    {errors.firstName.message}
                                                                </p>
                                                            )}
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextLname">
                                                        <Col md="12" lg="8" sm="12">
                                                            <Form.Label className={styles.formNameDetails}>
                                                                Last Name
                                                            </Form.Label>
                                                            <Form.Control
                                                                {...register("lastName")}
                                                                name="lastName"
                                                                type="text"
                                                                required
                                                                className={styles.formControlDetails}
                                                            />
                                                            {errors.firstName && (
                                                                <p className="mt-1 text-xs text-red-500">
                                                                    {errors.firstName.message}
                                                                </p>
                                                            )}
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextCname">
                                                        <Col md="12" lg="8" sm="12">
                                                            <Form.Label className={styles.formNameDetails}>
                                                                Company Name
                                                            </Form.Label>
                                                            <Form.Control
                                                                className={styles.formControlDetails}
                                                                {...register("companyName")}
                                                                name="companyName"
                                                                type="text"
                                                                required
                                                            />
                                                            {errors.companyName && (
                                                                <p className="mt-1 text-xs text-red-500">
                                                                    {errors.companyName.message}
                                                                </p>
                                                            )}
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextNumber">
                                                        <Col md="12" lg="8" sm="12">
                                                            <Form.Label className={styles.formNameDetails}>
                                                                Phone Number
                                                            </Form.Label>
                                                            <Controller
                                                                name="phoneNumber"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <PhoneInput
                                                                        {...field}
                                                                        country="us"
                                                                        enableSearch
                                                                        inputStyle={{ width: "100%", height: 48 }}
                                                                        dropdownClass="absolute z-50"
                                                                        dropdownStyle={{ width: "30vw", minWidth: 300 }}
                                                                        containerClass="relative h-[48px] mb-3"
                                                                    />
                                                                )}
                                                            />
                                                            {errors.phoneNumber && (
                                                                <p className="mt-1 text-xs text-red-500">
                                                                    {errors.phoneNumber.message}
                                                                </p>
                                                            )}
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                                                        <Col md="12" lg="8" sm="12">
                                                            <Form.Label className={styles.formNameDetails}>
                                                                Email
                                                            </Form.Label>
                                                            <Form.Control
                                                                className={styles.formControlDetails}
                                                                {...register("email")}
                                                                name="email"
                                                                type="email"
                                                                required
                                                            />
                                                            {errors.email && (
                                                                <p className="mt-1 text-xs text-red-500">
                                                                    {errors.email.message}
                                                                </p>
                                                            )}
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} className="mb-3" controlId="pormPlaintextPriority">
                                                        <Col md="12" lg="8" sm="12">
                                                            <Form.Label className={styles.formNameDetails}>
                                                                Priority
                                                            </Form.Label>
                                                            <Form.Select
                                                                {...register("priority")} // Register priority
                                                                className={styles.formControlDetails}
                                                                required
                                                            >
                                                                <option value="">Select Priority</option>
                                                                <option value="Low">Low</option>
                                                                <option value="Medium">Medium</option>
                                                                <option value="High">High</option>
                                                            </Form.Select>
                                                            {errors.priority && (
                                                                <p className="mt-1 text-xs text-red-500">
                                                                    {errors.priority.message}
                                                                </p>
                                                            )}
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextSubjects">
                                                        <Col md="12" lg="8" sm="12">
                                                            <Form.Label className={styles.formNameDetails}>
                                                                Subjects
                                                            </Form.Label>
                                                            <Form.Control
                                                                className={styles.formControlDetails}
                                                                {...register("subject")}
                                                                name="subject"
                                                                type="text"
                                                                required
                                                            />
                                                            {errors.email && (
                                                                <p className="mt-1 text-xs text-red-500">
                                                                    {errors.subject.message}
                                                                </p>
                                                            )}
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextText">
                                                        <Col md="12" lg="8" sm="12">
                                                            <Form.Label className={styles.formNameDetails}>
                                                                Description
                                                            </Form.Label>
                                                            <Form.Control
                                                                as="textarea"
                                                                rows={3}
                                                                {...register("feedback")}
                                                                name="feedback"
                                                                type="text"
                                                                required
                                                            />
                                                            {errors.feedback && (
                                                                <p className="mt-1 text-xs text-red-500">
                                                                    {errors.feedback}
                                                                </p>
                                                            )}
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextButton">
                                                        <Col md="12" lg="8" sm="12">
                                                            <Form.Label className={styles.formNameDetails}>
                                                            </Form.Label>
                                                            <Button variant="primary" className={styles.submitFormbtn} type="submit">
                                                                Submit
                                                            </Button>
                                                        </Col>
                                                    </Form.Group>
                                                </Form>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </Col>
                    </Tab.Container>
                    <Modal
                        className={styles.modalLarge}
                        size="lg"
                        show={show}
                        onHide={() => setShow(false)}
                        aria-labelledby="example-modal-sizes-title-lg"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-modal-sizes-title-lg">
                                <strong> Connect with Our Support Agent</strong>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <iframe
                                src="https://app.squarespacescheduling.com/schedule.php?owner=24269515"
                                title="Schedule Appointment"
                                width="100%"
                                height="500"
                                frameBorder="0"
                                style={{ maxHeight: 'none', overflow: 'hidden', height: '360px' }}
                            ></iframe>
                            <script src="https://embed.acuityscheduling.com/js/embed.js" type="text/javascript"></script>

                        </Modal.Body>
                    </Modal>

                    <Modal
                        className={styles.modalLarge}
                        size="lg"
                        show={zohoShow}
                        onHide={() => setZohoShow(false)}
                        aria-labelledby="example-modal-sizes-title-lg"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-modal-sizes-title-lg">
                                {/* <strong>Get Help With Our Support Agent</strong> */}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <iframe width='100%' height='750px' src='https://exportgenius.zohobookings.in/portal-embed#/schedule-demo' frameborder='0' allowfullscreen='' > </iframe>

                        </Modal.Body>
                    </Modal>
                </Row>
            </Container>
        </div>
    );
}

export default HelpCenterTabsArea;