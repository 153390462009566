import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./BannerArea.module.css";
import Container from 'react-bootstrap/Container';
import { Col, Row, Nav, Tab } from "react-bootstrap";
import React, { useEffect } from 'react';

function BannerArea() {

    return (

        <div className={styles.BannerAreaBg}>
            <Container>
                <Row>
                    <Col lg={12}>
                        <div className={styles.bgCircle}>
                            <Row>
                                <Col md={6}>
                                    <div className={styles.abouEgs}>
                                        <img src="helpCenterBanner.png" alt="Help Center" />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className={styles.abouEgGns}>
                                        <h3>Help & Support</h3>
                                        <h1>How Can We Help You?</h1>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

            </Container>
        </div>
    );
}

export default BannerArea;