import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./QuickLinksbox.module.css";
import Container from 'react-bootstrap/Container';
import { Col, Row } from "react-bootstrap";

function QuickLinksbox() {

    return (

        <div className={styles.QuickLinksboxSection}>
            <Container>
                <Row>
                    <Col lg={12}>
                        <div className={styles.qudklNks}>
                            <h2>Quick Links</h2>
                        </div>
                        <div className={styles.quicllinYTabs}>
                            <div className={styles.LinksofBoxex}>
                                <a href="/faq">
                                    <img alt="faq" src="/faqIcon.png" />
                                    <p>FAQ</p>
                                </a>
                            </div>
                            <div className={styles.LinksofBoxex}>
                                <a href="/data">
                                    <img alt="data" src="/dataIcon.png" />
                                    <p>Data Availability</p>
                                </a>
                            </div>
                            <div className={styles.LinksofBoxex}>
                                <a href="/pricing">
                                    <img alt="plan" src="/planIcon.png" />
                                    <p>Plan & Pricing</p>
                                </a>
                            </div>
                            <div className={styles.LinksofBoxex}>
                                <a href="contact-us">
                                    <img alt="contact" src="/contactIcon.png" />
                                    <p>Contact Us</p>
                                </a>
                            </div>
                        </div>
                    </Col>
                </Row>

            </Container>
        </div>
    );
}

export default QuickLinksbox;